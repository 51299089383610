import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  servicedata: any = [];
  name: any;
  email: any;
  mobile_no: any;
  residence: any;
  history: any;
  reference: any;
  certificate: any;
  summary: any;
  successMessage: any = '';
  errorMessage: any = '';
  data: any;
  @ViewChild('close', { static: false }) close: ElementRef

  constructor(public common: CommonService) { }

  ngOnInit() {
  }

  coachenquiry() {
    this.servicedata = {
      name: this.name,
      email: this.email,
      residence: this.residence,
      summary: this.summary,
      certificate: this.certificate,
      history: this.history,
      reference: this.reference,
      mobile: this.mobile_no
    }
    this.common.serverdatapost('addCoachInquiry', this.servicedata).subscribe(
      res => {
        this.data = res;
        console.log(this.data);
        if (this.data.status) {
          alert(this.data.message)
          this.close.nativeElement.click();
          this.name = ''
          this.email = ''
          this.mobile_no = ''
          this.residence = ''
          this.history = ''
          this.reference = ''
          this.certificate = ''
          this.summary = ''
        }
      },
      err => {
        console.log(err)
      }
    );
  }


}
