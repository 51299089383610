
import { Component,  OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css',"../../assets/lib/bootstrap/css/bootstrap.css"],
  
})
export class TabsComponent implements OnInit {
  images: string[] = [
    '../../assets/images/3CB610F8-0B01-4073-8E99-7B71F011DFB5.jpg',
    '../../assets/images/3CB610F8-0B01-4073-8E99-7B71F011DFB5.jpg',
    '../../assets/images/3CB610F8-0B01-4073-8E99-7B71F011DFB5.jpg',
   '../../assets/images/3CB610F8-0B01-4073-8E99-7B71F011DFB5.jpg'
  ];
  activeIndex = 0;
  intervalId: any;

  constructor() { }

  ngOnInit(): void {
   
  }

  

 


}