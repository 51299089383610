import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  serviceurl: any = "https://friscobadminton.workingorders.com/api/services/v2/";
  // serviceurl:any="https://development.workingorders.com/api/services/v2/";
  // serviceurl:any="http://127.0.0.1:8000/api/services/v1/";

  servicedataformated: any;
  servicedata: any = []
  data: any = []

  httpOptions = {
    headers: new HttpHeaders({
      'Enctype': 'multipart/form-data'
    }),

  }

  constructor(private http: HttpClient) { }

  serverdata(serviceurl, servicedata) {
    if (servicedata.length > 0) {
      let i = 0;
      for (let obj of servicedata) {
        for (let key in obj) {
          if (i == 0) {
            this.servicedataformated = serviceurl + "?" + key + "=" + obj[key];
          }
          else {
            this.servicedataformated += "&" + key + "=" + obj[key];
          }
        }
        i++;
      }
    }
    else {
      this.servicedataformated = serviceurl;
    }

    return this.http.get(this.serviceurl + this.servicedataformated, this.httpOptions);
  }

  serverdatapost(service, servicedata) {
    return this.http.post(this.serviceurl + service, servicedata, this.httpOptions);
  }

}
