import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  servicedata:any;
  email:any;
  password:any;
  data:any;
  errorMsg:any;
  member_id:any;
  loginSelect:any = true;
  lookupSelect:any = false;
  plbtn:any = true;

  constructor(private common:CommonService, private router:Router) {
    // if()
   }

  ngOnInit() {
  }

  login(){
    this.servicedata = {
      email:this.email,
      password:this.password,
      login_check:'0'
    }
  
  this.common.serverdatapost('memberLogin',this.servicedata).subscribe(
    res => {
          this.data = res;

          console.log(this.data);

          if(this.data.status == true)
          {
            localStorage.setItem('currentUserData',this.data.response);
            localStorage.setItem('emailID',this.email);
            localStorage.setItem("memberId",this.data.member_id);
            localStorage.setItem("isLoggedIn","yes")
            this.router.navigate(['dashboard'])
            .then(() => {
              window.location.reload();
            });
          }
          else
          {
            alert(this.data.message);
          }
    },
    errors => {
      let err = errors;
      this.errorMsg = err.message;
      console.log(errors);
    }
  )
  }

  lookup(){

    this.loginSelect = false;
    this.lookupSelect = true;
    this.plbtn = true;

  }
  passwordLookup(){

    this.plbtn = false;

      this.servicedata = {
        member_id:this.member_id,
        email:this.email
      }
  
      this.common.serverdatapost('forgotMemberPassword',this.servicedata).subscribe(
        res => {
              this.data = res;
              alert(this.data.message);
              if(this.data.status)
              {
                this.loginSelect = true;
                this.lookupSelect = false;
              }
              else{
                this.plbtn = true;
              }
        },
        errors => {
          let err = errors;
          this.errorMsg = err.message;
          console.log(errors);
        }
      )

  }

  back(){
    this.loginSelect = true;
    this.lookupSelect = false;
  }
}
