import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { FooterComponent } from "./footer/footer.component";
// import { TrainingComponent } from "./training/training.component";
import { HeaderComponent } from "./header/header.component";
// import { ContactComponent } from "./contact/contact.component";
// import { AboutComponent } from "./about/about.component";
// import { ChessclassComponent } from "./chessclass/chessclass.component";
// import { EventComponent } from "./event/event.component";
// import { UpcomingComponent } from "./upcoming/upcoming.component";
// import { MembershipComponent } from "./membership/membership.component";
// import { FbaRulesComponent } from "./fba-rules/fba-rules.component";
import { LoginComponent } from "./login/login.component";
import { ProfileComponent } from "./profile/profile.component";
import { DashboardComponent } from './dashboard/dashboard.component';
import { TrainingclassComponent } from './trainingclass/trainingclass.component';
import { ReservecourtComponent } from './reservecourt/reservecourt.component';
import { GroupComponent } from './group/group.component';
import { AuthGuard } from './guard/auth.guard';
import { MembershipContractComponent } from './membership-contract/membership-contract.component';
// import{BlogsComponent} from './blogs/blogs.component';
import{ManageBlogComponent} from './manage-blog/manage-blog.component';
import { BlogdetailComponent } from "./blogdetail/blogdetail.component";
import { CreateBlogComponent } from "./create-blog/create-blog.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { LatestNewsComponent } from "./latest-news/latest-news.component";


// import { AchievementComponent } from "./achievement/achievement.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "footer", component: FooterComponent },
  { path: "header", component: HeaderComponent },
  // { path: "contact-us", component: ContactComponent },
  // { path: "about-us", component: AboutComponent },
  // { path: "chess-class", component: ChessclassComponent },
  // { path: "event", component: EventComponent },
  // { path: "upcoming-camps", component: UpcomingComponent },
  // { path: "training-program-and-coaches", component: TrainingComponent },
  // { path: "membership", component: MembershipComponent },
  { path: "membership-contract", component: MembershipContractComponent },
  // { path: "our-rules", component: FbaRulesComponent },
  { path: "login", component: LoginComponent },
  { path: "dashboard", component: DashboardComponent,canActivate:[AuthGuard] },
  { path: "profile", component: ProfileComponent,canActivate:[AuthGuard] },
  { path: "enroll-class", component: TrainingclassComponent},
  { path: "reserve-court", component: ReservecourtComponent,canActivate:[AuthGuard] },
  { path: "group", component: GroupComponent,canActivate:[AuthGuard] },
  // {path:"blogs",component: BlogsComponent},
  {path:"manage-blog",component: ManageBlogComponent},
  {path:"blogdetail/:id",component:BlogdetailComponent},
  {path:"create-blog",component:CreateBlogComponent},
//  {path:"news",component:LatestNewsComponent},
  
  // {path:"achievements",component:AchievementComponent},
  { 
    path: 'videos', 
    loadChildren: () => import('./about/about.module').then( m => m.AboutModule),
  },
  { 
    path: 'achievements', 
    loadChildren: () => import('./achievement/achievement.module').then( m => m.AchievementModule),
  },
  // { 
  //   path: 'blogdetail', 
  //   loadChildren: () => import('./blogdetail/blogdetail.module').then( m => m.BlogdetailModule),
  // }
  { 
    path: 'blogs', 
    loadChildren: () => import('./blogs/blogs.module').then( m => m.BlogsModule),
  },
  { 
    path: 'chess-class', 
    loadChildren: () => import('./chessclass/chessclass.module').then( m => m.ChessclassModule),
  },
  { 
    path: 'contact-us', 
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactModule),
  },
  { 
    path: 'event', 
    loadChildren: () => import('./event/event.module').then( m => m.EventModule),
  },
  { 
    path: 'our-rules', 
    loadChildren: () => import('./fba-rules/fba-rules.module').then( m => m.FbaRulesModule),
  },
  { 
    path: 'training-program-and-coaches', 
    loadChildren: () => import('./training/training.module').then( m => m.TrainingModule),
  },
  { 
    path: 'membership', 
    loadChildren: () => import('./membership/membership.module').then( m => m.MembershipModule),
  },
  { 
    path: 'upcoming-camps', 
    loadChildren: () => import('./upcoming/upcoming.module').then( m => m.UpcomingModule),
  },
  // { 
  //   path: 'enroll-class', 
  //   loadChildren: () => import('./trainingclass/trainingclass.module').then( m => m.TrainingClassModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
